<template>
  <div>
    <!-- Business Info Block-->
    <v-sheet :color="wsLIGHTCARD" class="d-flex">
      <v-sheet class="px-2 mx-auto" color="transparent" style="margin-bottom: 140px; margin-top: 200px" width="1240">


        <v-divider :style="`border-color : ${wsBACKGROUND}`"/>

        <v-row class="mt-10">
          <v-col cols="12" sm="7">
            <div class="fill-height d-flex align-center">
              <h1 :style="`color : ${wsDARKER}; font-size: ${!SM ? 42 : 28}px`">
                {{ $store.state.business.selectedBusiness.name }}
              </h1>
            </div>
          </v-col>
          <v-col cols="12" sm="5">
            <div class="fill-height d-flex align-center">
              <h3 :style="`color : ${wsDARKER};font-size: ${!SM ? 18 : 14}px`"
                  class="font-weight-regular"
              >
                {{ $store.state.business.selectedBusiness.description }}
              </h3>

            </div>
          </v-col>

        </v-row>

      </v-sheet>

    </v-sheet>
    <!-- Block Courses Title-->
    <v-sheet class="d-flex">
      <v-sheet class="px-2 mx-auto pt-10" color="transparent" width="1240">
        <h1>{{ $t('Courses') }}</h1>
      </v-sheet>
    </v-sheet>
    <!-- Block Courses -->
    <view-block
        v-if="!hasNoCourses"
        :block="blockCourses"
        default-site
        @no-items="handleNoItems"
    />
    <v-sheet v-else class="d-flex pb-12">
      <v-sheet class="pa-6 mx-auto mt-6 " :style="`border : 1px solid ${wsBACKGROUND} !important`" color="transparent" width="1240">
        <h3 class="font-weight-regular text-center">{{ $t('avalon.default.no_active_courses') }}</h3>
      </v-sheet>
    </v-sheet>


  </div>

</template>

<script>
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";

export default {
  name: "DefaultSiteHome",
  components: {
    viewBlock
  },
  data() {
    return {
      blockCourses: {
        type: 'tiles_courses',
        config: {
          "style": "light_1",
          "width": 1240,
          "design": "list_small",
          "gutter": 40,
          "pagination": true,
          "padding_top": 40,
          "padding_top_sm": 40,
          "block_radius": 4,
          "color_styles": [],
          "fonts_styles": [],
          "image_height": 200,
          "columns_large": 3,
          "display_images": true,
          "items_per_page": 10,
          "padding_bottom": 75,
          "padding_bottom_sm": 40,
          "gutter_vertical": 32,
          "display_category": true,
          "visibility_mobile": true,
          "display_categories": true,
          "visibility_desktop": true,
          "display_description": true
        },
      },
      hasNoCourses : false
    }
  },
  methods : {
    handleNoItems() {
      this.hasNoCourses = true
    }
  }
}
</script>

<style scoped>

</style>