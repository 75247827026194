<template>
<v-sheet   class="d-flex">
  <v-sheet width="1240" class="d-flex align-center justify-space-between py-2 px-2 mx-auto">
    <!-- LOGO -->

    <router-link  :to="businessPublicLink('')">
      <img class="text-center pointer  mt-2"
           :height="!SM ? 32 : 24"
           width="auto"
           alt="WeStudy"
           src="/library/img/westudy_logo.png"
      />
    </router-link>

    <div class="d-flex align-center">
      <ws-lang-select
          class="mr-4"
          :text-color="wsACCENT"
          :activator-style="`color : ${wsACCENT}`"
          :text-style="`color : ${wsACCENT}`"
          autohide
      />
      <ws-button
          :to="businessDashLink('')"
          :label="!$store.state.auth.loggedIn ? 'Login' : 'PersonalDashboard' " />
    </div>

  </v-sheet>
</v-sheet>
</template>

<script>
import wsLangSelect from "@/components/UI/wsLangSelect";
export default {
  name: "defaultSiteHeader",
  components : {
    wsLangSelect
  }
}
</script>

<style scoped>

</style>